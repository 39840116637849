import api from './axiosConfig';
import React, { useState } from 'react';
import Actors from './Actors';

const UploadLargeFile = () => {
  const [file, setFile] = useState(null);          // Wybrany plik
  const [fileName, setFileName] = useState('');     // Nazwa pliku na serwerze
  const [uploadProgress, setUploadProgress] = useState(0);
  const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
  const [selectedActor, setSelectedActor] = useState(null);

  // Ustawienie pliku po wyborze
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // Ustawienie nazwy pliku
  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  const uploadFileInChunks = async () => {
    if (!file) {
      alert('Wybierz plik przed rozpoczęciem przesyłania');
      return;
    }

    if (!fileName) {
      alert('Podaj nazwę pliku, pod którą ma zostać zapisany na serwerze');
      return;
    }

    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let start = 0;

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const end = Math.min(start + CHUNK_SIZE, file.size);
      const chunk = file.slice(start, end);  // Podziel plik na część

      const formData = new FormData();
      formData.append('file', chunk);
      formData.append('chunkIndex', chunkIndex);
      formData.append('totalChunks', totalChunks);
      formData.append('fileName', fileName);  // Dodajemy nazwę pliku do FormData
      formData.append("actors", selectedActor);

      try {
        await api.post('ManageFiles/upload', formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((chunkIndex + progressEvent.loaded / progressEvent.total) * (100 / totalChunks));
            setUploadProgress(percentCompleted);  // Aktualizacja postępu
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => console.log(response))

        start = end;  // Aktualizuj start do następnego fragmentu
      } catch (error) {
        console.error('Błąd przesyłania fragmentu', error);
        return; // Przerwij w razie błędu
      }
    }
    alert('Przesyłanie zakończone');
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} /> {/* Wybór pliku */}
      <input
        type="text"
        placeholder="Podaj nazwę pliku na serwerze"
        value={fileName}
        onChange={handleFileNameChange}
      /> {/* Wybór nazwy pliku */}
      <Actors onSelect={(actorName) => setSelectedActor(actorName)} />
      <button onClick={uploadFileInChunks} disabled={!file || !fileName}>
        Upload File
      </button>
      <div>Upload Progress: {uploadProgress}%</div>
    </div>
  );
};

export default UploadLargeFile;
