import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import VideoPlayer from './components/VideoPlayer';
import MovieCatalog from './components/MoviesPage';
import Logout from './components/Logout';
import UploadFile from './components/UploadFile';
import AdminPanel from './components/MoviesAdminPanel';

import { jwtDecode } from 'jwt-decode';
import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
const [admin, setAdmin] = useState(false)
const [user, setUser] = useState(false)

useEffect(() => {
  const token = localStorage.getItem('token');
  if (token) {
    const decoded = jwtDecode(token);
    setUser(true);
    if(decoded.role === 'admin')
    {
      setAdmin(true)
    }
  }
}, [])
 
  return (
    <Router>
      <nav>
        <Link to="/">Home</Link> {!user && <Link to="/login">Login</Link>} {!user && <Link to="/register">Register</Link>} {user && <Link to="catalog">Catalog</Link>} {user && <Link to="/logout">Logout</Link>} {admin && <Link to="adminPanel">Panel Administracyjny</Link>}
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/catalog" element={<MovieCatalog />} />
        <Route path="/video/:id" element={<VideoPlayer />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/uploadFile" element={<UploadFile />} />
        <Route path="/adminPanel" element={<AdminPanel />} />
      </Routes>
    </Router>
  );
};

export default App;