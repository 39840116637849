import React from 'react';
import './Home.css'; // Stwórz plik CSS, aby dodać style do Home Page

const Home = () => {
  return (
    <div className="home-container">
      <h1>Welcome to Our App!</h1>
      <p>This is the Home Page of our React application.</p>
      <button className="home-button">Get Started</button>
    </div>
  );
};

export default Home;
