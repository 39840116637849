import React, { useState } from 'react';
import MoviesCatalog from './MovieCatalog';
import SearchBar from './Search';

const MoviesPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);

  const handleSearch = (query) => {
    setSearchQuery(query); // Aktualizacja zapytania
  };

  const handleError = (error) => {
    if (error === 'access denied') {
      setError('access denied');
    }
  };

  return (
    <div>
      {!error && <SearchBar onSearch={handleSearch} />}
      <MoviesCatalog searchQuery={searchQuery} onError={handleError} />
    </div>
  );
};

export default MoviesPage;
