import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from './axiosConfig';

const Actors = ({ onSelect }) => {
  // const [actors, setActors] = useState([]);
  const [options, setOptions] = useState([]); // Opcje dla react-select

  useEffect(() => {
    const fetchActors = async () => {
      try {
        const response = await api.get('/actors/get-actors');
        const fetchedActors = response.data.$values;

        // Przekształć dane na format używany przez react-select
        const formattedOptions = fetchedActors.map((actor) => ({
          value: actor.name,
          label: actor.name,
        }));

        // setActors(fetchedActors);
        setOptions(formattedOptions);
      } catch (err) {
        console.error('Error fetching actors', err);
      }
    };

    fetchActors();
  }, []);

  return (
    <Select
      options={options} // Opcje do wyboru
      onChange={(selectedOption) => onSelect(selectedOption.value)} // Wybierz wartość
      isSearchable // Włącza wyszukiwanie
      placeholder="Wybierz aktora..."
      noOptionsMessage={() => "Brak wyników"}
    />
  );
};

export default Actors;
